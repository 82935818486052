<template>
  <div class="row">
    <div class="card">
      <div class="card-body"><h1>Dashboard</h1></div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>